import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View, Image, ScrollView, Modal, DimensionValue } from "react-native";
import ImageViewer from "./ImageViewer";

type Props = {
    images: Array<string>;
    style: { height: DimensionValue; width: DimensionValue };
    imageBorderRadius: number;
    imageHeight: number;
    imageWidth: number;
};

export default function ImageModal(props: Props) {
    const { images, style, imageBorderRadius, imageHeight, imageWidth } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState<number>(0);
    const filterImages = images.filter((img) => img != null);
    const imageComponents: Array<JSX.Element> = filterImages.map((i, idx) => (
        <TouchableOpacity key={idx} onPress={() => onPressImage(idx)}>
            <Image
                key={idx}
                source={{ uri: i }}
                style={[
                    styles.imageStyle,
                    {
                        borderRadius: imageBorderRadius,
                        height: imageHeight,
                        width: imageWidth,
                    },
                ]}
            />
        </TouchableOpacity>
    ));

    const onPressImage = (index: number) => {
        setCurrentImage(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <View style={{flex: 1}}>
            {!!imageComponents && (
                <ScrollView horizontal={true} style={style}>
                    {imageComponents}
                </ScrollView>
            )}
            <Modal onRequestClose={closeModal} animationType="fade" visible={isOpen} transparent={true}>
                <ImageViewer
                    onPress={closeModal}
                    imageUrl={filterImages[currentImage]}
                />
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    imageStyle: {
        marginRight: 9,
    },
});

import React from "react";
import { TouchableOpacity } from "react-native";
import { CampusDTO } from "react/api/campus/dto/CampusDTO";
import { useCampusPinData, useCampusPinPressHandler } from "./campus.constants";
import Strings from "react/util/localization/localization";
import { MarkerContent } from "react/components/map/MarkerContent";
import { ParkInfo } from "react/model/ParkInfo";

export type CampusPinProps = {
    info: CampusDTO;
    parks?: { [key: number]: ParkInfo };
};

export const CampusPinWeb = ({ info, parks }: CampusPinProps) => {
    const { containsFavouritePark, hasAnOpenPark, isOrgMember, hasEvCharger, isCampusFull } = useCampusPinData(
        info,
        parks
    );
    const handlePress = useCampusPinPressHandler(info);

    return (
        <TouchableOpacity onPress={handlePress} activeOpacity={1}>
            <MarkerContent
                iconName={"buildingwork"}
                markerText={hasAnOpenPark ? "" : Strings("closed")}
                parkIsOpen={hasAnOpenPark}
                userIsOrgMember={isOrgMember}
                favPark={containsFavouritePark}
                parkIsFull={isCampusFull}
                hasEVCharger={hasEvCharger}
            />
        </TouchableOpacity>
    );
};

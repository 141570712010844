import { useReduxMaps } from "react/redux/redux";
import { CampusDTO } from "react/api/campus/dto/CampusDTO";
import getParkAvailability from "react/util/getParkAvailability";
import { useFavouriteParkIds } from "../pin.constants";
import { useNavigation } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import { userIsOrgMember as checkOrgMember } from "react/util/getUserMember";
import { useUserRoles } from "react/api/user/user.api";
import { getRelevantBays } from "react/components/map/ParkMarkerBuilder";
import { ParkInfo } from "react/model/ParkInfo";

export const useCampusPinData = (args: Pick<CampusDTO, "parks">, mapParks?: {[key: number]: ParkInfo}) => {
    const { parks } = args;
    const { preferences: { parkingType }} = useReduxMaps();
    const favouriteParkIds = useFavouriteParkIds();
    const { userRoles } = useUserRoles();

    const organisationId = mapParks && mapParks[parks?.[0].parkId]?.organisationId;
    const hasEvCharger = parks.some((p) => p.numberOfEVBays);
    const hasAnOpenPark = parks.some((park) => getParkAvailability(park).isOpenNow);
    const containsFavouritePark = favouriteParkIds.some((id) => parks.some((pInfo) => pInfo.parkId === id));
    const isOrgMember = organisationId != null && checkOrgMember(userRoles, organisationId);
    const isCampusFull = !parks.reduce((a, b) => a + getRelevantBays(b, parkingType), 0);

    return {
        hasAnOpenPark,
        containsFavouritePark,
        isOrgMember,
        hasEvCharger,
        isCampusFull
    };
};

export const useCampusPinPressHandler = (args: Pick<CampusDTO, "id" | "organisation">) => {
    const { id: campusId, organisation: organisationId} = args;
    const { preferences } = useReduxMaps();
    const navigation = useNavigation();

    return () => {
        navigation.navigate(Routes.CampusScreenView, {
            campusId: campusId,
            organisationId,
            parkingType: preferences.parkingType,
        });
    };
};
